<script setup lang="ts">
import { POINTS, DATE, BALANCE } from '@/locales/constants';
import { getTransactionContent } from '@/views/history/views/transactions/components/transactions';
import { localizePoints, toDate } from '@/utils/helpers';
import type { Transaction } from '@/types';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { transaction } = defineProps<{
	transaction: Transaction;
}>();

const { localizationCookie } = useAppCookies();
const localization = localizationCookie || 'en-US';

const { term, icon, color } = getTransactionContent(transaction.type);
</script>

<template>
	<div class="transaction-item">
		<div class="transaction-row">
			<div class="transaction-row-item">
				<PrimeText color="disabled" tag="div" weight="500" size="xs">
					{{ $t(POINTS) }}
				</PrimeText>
				<PrimeText
					tag="div"
					size="xs"
					weight="600"
					:color="transaction.coins < 0 ? 'secondary' : 'primary'"
				>
					{{ localizePoints(transaction.coins) }}
				</PrimeText>
			</div>
			<div class="transaction-row-item">
				<PrimeText size="xs" weight="500">{{ $t(term) }}</PrimeText>
				<component :is="icon" size="1.25rem" :color="color" />
			</div>
		</div>

		<div class="transaction-row">
			<div class="transaction-row-item">
				<PrimeText tag="div" size="xs" weight="500" color="disabled">
					{{ $t(BALANCE) }}
				</PrimeText>
				<PrimeText tag="div" size="xs" weight="500">
					{{ localizePoints(transaction.balance) }}
				</PrimeText>
			</div>
			<div class="transaction-row-item">
				<PrimeText tag="div" size="xs" weight="500" color="disabled">
					{{ $t(DATE) }}
				</PrimeText>
				<PrimeText tag="div" size="xs" weight="500">
					{{ toDate(transaction.date, localization) }}
				</PrimeText>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.transaction-item {
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
	padding: 0.875rem;
	background: var(--white);
	border-radius: 0.875rem;
	margin-bottom: 1.125rem;
	box-shadow: var(--box-shadow);

	&:last-child {
		margin-bottom: 0;
	}
}

@include breakpoint(mobile) {
	.transaction-item {
		margin-bottom: 1.125rem;
	}
}

.transaction-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;
}

.transaction-row-item {
	display: flex;
	gap: 0.25rem;
}
</style>
