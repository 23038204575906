import { createRouter, createWebHistory } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { authGuard, emailSubscriptionsGuard, loadingGuard, localeGuard } from '@/router/guards';
import { authRoutes } from '@/router/routes/auth';
import { userRoutes } from '@/router/routes/user';
import { utilityRoutes } from '@/router/routes/utility';

const router = createRouter({
	history: createWebHistory(),
	routes: [...userRoutes, ...authRoutes, ...utilityRoutes],
});

router.afterEach((to) => {
	if (to.name) {
		const appStore = useAppStore();
		appStore.setCurrentScreen(to.name as any).then(() => {});
	}
});

router.beforeEach(async (to, _from, next) => {
	await localeGuard(to, _from, next);
	await authGuard(to, _from, next);
	await loadingGuard(to, _from, next);
	await emailSubscriptionsGuard(to, _from, next);
});

export default router;
