<script lang="ts" setup>
import { onMounted } from 'vue';
import { usePrimeSurveysIntegration } from '@/utils/composables/useIntegrationScriptRender';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { renderPrimeSurveys, integrationScriptLoading } =
	usePrimeSurveysIntegration();

onMounted(() => renderPrimeSurveys('#ps-offers-page', 'surveys', '', 'offers'));
</script>

<template>
	<main class="integrated-page">
		<PrimeLoaderSpinner v-if="integrationScriptLoading" />
		<div id="ps-offers-page" class="integrated-container" />
	</main>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

#ps-offers-page {
	:deep(.integration-script-container) {
		.list-items-wrapper,
		.filter {
			background: none;
		}

		.container {
			padding-left: 0.25rem;
			padding-right: 0.25rem;
		}
	}
}

.integrated-page {
	width: 100%;
}

.integrated-container {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>
