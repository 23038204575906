<script setup lang="ts">
import { localizePoints } from '@/utils/helpers';
import { COINS, EARNED } from '@/locales/constants';
import type { LeaderboardUser } from '@/types';
import TierCircle from '@/components/tiers/TierCircle.vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const { user } = defineProps<{
	user: LeaderboardUser;
}>();

const { isMobile } = useScreenSize();
</script>

<template>
	<div class="leaderboard-table__user-row">
		<div class="place">
			<PrimeText tag="span" size="xs" weight="600" color="primary">
				{{ user.rank }}
			</PrimeText>
		</div>
		<div class="username-score">
			<template v-if="user.username">
				<div class="username">
					<PrimeText size="sm" weight="600">{{ user.username }}</PrimeText>
					<TierCircle
						v-if="user?.level && user?.level_tier"
						size="1.375rem"
						:tier-key="user.level_tier"
						:level="user.level"
						level-size="0.75rem"
						min-width="1.938rem"
						rounded="9.375rem"
					/>
				</div>
				<PrimeText tag="div" size="sm" weight="500" class="score">
					{{ localizePoints(user.earned) }}
					{{ $t(EARNED) }}
				</PrimeText>
			</template>

			<div v-else class="user-score-skeleton" />
		</div>
		<div class="prize">
			<PrimeText color="primary" weight="500" :size="isMobile ? 'xs' : 'sm'">
				{{ localizePoints(user.prize) }}
			</PrimeText>
			<PrimeText color="primary" weight="500" :size="isMobile ? 'xs' : 'sm'">
				{{ $t(COINS) }}
			</PrimeText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.leaderboard-table__user-row {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	padding: 0.625rem 0;
	border-bottom: 1px solid var(--grey-200);

	.place {
		flex: none;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 2.25rem;
		min-height: 2.25rem;
		border-radius: 0.75rem;
		padding: 0.5rem;
		background: var(--grey-100);
	}

	&:nth-child(-n + 3) > .place {
		background-color: var(--base-secondary-100);
		.p-text {
			color: var(--base-secondary);
		}
	}

	.username-score {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
	}

	.username {
		display: flex;
		align-items: center;
		gap: 0.25rem;
		flex-wrap: wrap;

		.p-text {
			word-break: break-all;
		}
	}

	.prize {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.188rem;
		flex: none;
	}

	& .user-score-skeleton {
		width: 3.75rem;
		height: 0.75rem;
		border-radius: 3.125rem;
		background-color: var(--base-primary-04);
	}
}
</style>
