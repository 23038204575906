<script lang="ts" setup>
import { ref, watch, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { changeTimeZone } from '@/api';
import { useI18n } from 'vue-i18n';
import {
	PrimeButton,
	notify,
	PrimeDropdown,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { timezones } from '@/constants/timezones';
import {
	CHANGE_TIMEZONE,
	SAVE,
	TIMEZONE_UPDATE_SUCCESS_MESSAGE,
	SEARCH,
} from '@/locales/constants';

type TimeZone = {
	code: string;
	label: string;
};

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const userStore = useUserStore();
const loading = ref(false);
const { timezone } = storeToRefs(userStore);
const userTimezone = ref<TimeZone>();
const { t } = useI18n();

const setUserTimezone = () => {
	const tz = timezones.find(
		(tz: Record<string, string>) => tz.code === timezone.value
	);
	if (!tz) return;
	userTimezone.value = { code: tz.code, label: tz.label };
};

watch(
	() => timezone,
	(value) => {
		if (value) {
			setUserTimezone();
		}
	},
	{ deep: true }
);

onBeforeMount(() => {
	setUserTimezone();
});

const handleSubmit = async () => {
	try {
		loading.value = true;
		if (!userTimezone.value) {
			throw new Error('Timezone is not set');
		}
		await changeTimeZone(userTimezone.value.code);
		userStore.setTimezone(userTimezone.value.code);
		handleCloseModal();
		notify({ body: t(TIMEZONE_UPDATE_SUCCESS_MESSAGE) });
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(CHANGE_TIMEZONE) }}</template>
		<template #inputs>
			<PrimeDropdown
				v-model="userTimezone"
				:data="timezones"
				:filter-placeholder="$t(SEARCH)"
				filter
			>
			</PrimeDropdown>
		</template>
		<template #actions>
			<PrimeButton full-width :label="$t(SAVE)" @click="handleSubmit" />
			<PrimeLoader v-if="loading" />
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
.modal-common-content {
	:deep(.content-header) {
		margin-bottom: 0;
	}
	:deep(.content-inputs .p-dropdown) {
		width: 100%;

		.p-dropdown-label {
			text-align: start;
		}
	}
	:deep(.content-actions) {
		margin-top: 1.375rem;
	}
}
</style>
