import { useRoute, useRouter } from 'vue-router';
import { onBeforeMount, ref, watch } from 'vue';

export const useHistoryView = () => {
	const route = useRoute();
	const router = useRouter();
	const activeTabId = ref<number | string>(0);

	onBeforeMount(() => {
		if (route.params.type !== activeTabId.value) {
			activeTabId.value = route.params.type as string;
		}
	});

	watch(activeTabId, async (value) => {
		await router.replace({
			name: String(route.name),
			params: { type: value },
		});
	});

	return {
		activeTabId,
	};
};
