import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useAppStore } from '@/stores/app';
import { useComponentName } from '@/utils/composables/useComponentName';
import { useSocialAuth } from '@/utils/composables/useSocialAuth';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import type { ComputedRef, Ref } from 'vue';
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { sendErrorInfo } from '@/utils/errorCatching';
import { Echo } from '@/utils/echo';
import { Platforms, SocialProviders } from '@/enums';
import { storeToRefs } from 'pinia';
import type { CurrencySymbol } from '@/types';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';

type CheckEmailForm = { email: string };

export const useAppLoginView = (): {
	getValidationFieldText: (
		field: keyof CheckEmailForm
	) => string | null | undefined;
	form: Ref<CheckEmailForm>;
	handleSubmitForm: () => Promise<void>;
	getValidationFieldStatus: (
		field: keyof CheckEmailForm
	) => 'error' | null | undefined;
	handleLoading: (socialProviderLoading: boolean) => void;
	socialAuthButtons: ComputedRef<string[]>;
	loading: Ref<boolean>;
	handleSocialAuth: (provider: string) => Promise<void>;
	currencySymbol: Ref<CurrencySymbol>;
	amount: number;
} => {
	const route = useRoute();
	const authStore = useAuthStore();
	const appStore = useAppStore();
	const { loading } = storeToRefs(authStore);
	const { appMeta } = storeToRefs(appStore);
	const componentName = useComponentName();
	const { socialAuth: handleSocialAuth } = useSocialAuth();
	const { platform } = useMobileApp();

	const isLoading = ref(false);
	const form = ref<CheckEmailForm>({} as CheckEmailForm);
	const serverValidationResults = ref();

	const { email } = useValidationRules();

	const validation = useVuelidate({ email }, form, {
		$autoDirty: false,
		$externalResults: serverValidationResults,
	});

	const {
		reset,
		clear,
		validate,
		isValidationError,
		getValidationFieldStatus,
		getValidationFieldText,
	} = useValidationUtils<CheckEmailForm>(validation);

	const handleSubmitForm = async (): Promise<void> => {
		clear();
		await validate();
		if (isValidationError.value) return;

		try {
			isLoading.value = true;
			await authStore.checkEmail(form.value.email);
		} catch (error) {
			notify({ body: authStore.error?.message || '' });
			await sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	onBeforeMount(async () => {
		const echo = Echo.getInstance();
		echo.removeConnections();
		authStore.setEmailNotVerified(false);
		authStore.setCheckedEmail('');
	});

	onMounted(async () => {
		if (
			route.query &&
			route.query.auth_token &&
			route.query.auth_email &&
			route.query.admin_id
		) {
			const {
				auth_token: token,
				auth_email: email,
				admin_id: adminId,
				is_support_tl: isSuperAdmin,
			} = route.query;
			await authStore.loginWithToken(
				token.toString(),
				email.toString(),
				adminId.toString(),
				Boolean(isSuperAdmin)
			);
		} else if (route.query && route.query.token_auth_url) {
			isLoading.value = true;
			const authUrl = route.query.token_auth_url.toString();
			await authStore.loginWithOneTimeToken(authUrl);
			isLoading.value = false;
		}
	});

	onBeforeUnmount(() => {
		form.value.email = '';
		reset();
	});

	const handleLoading = (socialProviderLoading: boolean) => {
		isLoading.value = socialProviderLoading;
	};

	const socialAuthButtons = computed(() => {
		return platform.value !== Platforms.ANDROID
			? Object.values(SocialProviders)
			: Object.values(SocialProviders).filter(
					(provider) => provider !== SocialProviders.APPLE
				);
	});
	const loadingState = computed(() => !appMeta.value || loading.value);
	const currencySymbol = computed(
		() => appMeta.value?.login_info?.currency_symbol
	);
	const amount = computed(() => appMeta.value?.login_info?.amount);

	return {
		form,
		handleSubmitForm,
		getValidationFieldStatus,
		getValidationFieldText,
		handleLoading,
		handleSocialAuth,
		socialAuthButtons,
		loading: loadingState,
		currencySymbol,
		amount,
	};
};
