<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	RATING_CURRENT,
	LEADERBOARD_CHECK,
	RATING_DESCRIPTION_WEEKLY,
	COMPLETE_SURVEY_INFO_TEXT,
} from '@/locales/constants';
import { PiTrophyDuotone } from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeText,
	PrimeButton,
	PrimeDivider,
	PrimeOverlayPanel,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const router = useRouter();
const route = useRoute();
const { leaderboard, currentLeaderboardRank } = storeToRefs(useUserStore());

const userRatingInfo = ref(false);
const completeSurveyInfo = ref(false);

const isUserUnlockedInLeaderboard = computed(() => {
	return leaderboard.value.unlocked;
});
const userHaveToCompleteSurvey = computed(() => {
	return (
		leaderboard.value.total_completes_required_to_unlock -
			leaderboard.value.successful_completes || 10
	);
});

const toggleUserRatingInfo = () => {
	userRatingInfo.value = !userRatingInfo.value;
};
const toggleCompleteSurveyInfo = () => {
	completeSurveyInfo.value = !completeSurveyInfo.value;
};
const toggleOverlayPanel = () => {
	!isUserUnlockedInLeaderboard.value
		? toggleUserRatingInfo()
		: toggleCompleteSurveyInfo();
};
const goToLeaderboard = () => {
	router.push({ name: 'leaderboard' });
	toggleUserRatingInfo();
};

watch(route, async () => {
	if (userRatingInfo.value) {
		userRatingInfo.value = false;
		completeSurveyInfo.value = false;
	}
});
</script>

<template>
	<div class="user-rating">
		<!-- Icon with user rating -->
		<button
			:class="{ 'active-leaderboard': isUserUnlockedInLeaderboard }"
			class="user-rating--button"
			@click="toggleOverlayPanel"
		>
			<PrimeText v-if="isUserUnlockedInLeaderboard" size="sm" weight="600">
				{{ currentLeaderboardRank }}
			</PrimeText>
			<PiTrophyDuotone color="inherit" />
		</button>

		<!-- OverlayPanel with user rating info -->
		<PrimeOverlayPanel
			id="user-rating--info"
			v-model="userRatingInfo"
			append-to=".user-rating--button"
			style="width: 16.25rem"
		>
			<header class="rating-info--header">
				<PiTrophyDuotone />
				<div class="rating-info--content">
					<PrimeText tag="h3" weight="600">
						{{ $t(RATING_CURRENT) }}: {{ currentLeaderboardRank }}
					</PrimeText>
					<PrimeText tag="h3" size="xs" color="grey-600">
						{{ $t(RATING_DESCRIPTION_WEEKLY) }}
					</PrimeText>
				</div>
			</header>
			<PrimeDivider color="grey-100" top-gap="0.625rem" bottom-gap="0.625rem" />
			<footer>
				<PrimeButton
					full-width
					rounded="md"
					:label="$t(LEADERBOARD_CHECK)"
					@click="goToLeaderboard"
				/>
			</footer>
		</PrimeOverlayPanel>

		<!-- OverlayPanel with complite survey info -->
		<PrimeOverlayPanel
			id="user-survay--info"
			v-model="completeSurveyInfo"
			class="black"
			append-to=".user-rating--button"
			style="width: 11.38rem"
		>
			<PrimeText tag="p" color="white" weight="500" size="xs" align="center">
				{{ $t(COMPLETE_SURVEY_INFO_TEXT, { value: userHaveToCompleteSurvey }) }}
			</PrimeText>
		</PrimeOverlayPanel>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.user-rating {
	flex: none;
	z-index: 1;

	&--button {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 2.125rem;
		height: 2rem;
		border: 0;
		border-radius: 3.125rem;
		color: var(--grey-450);
		background: var(--grey-50);
		font-weight: 600;
		cursor: pointer;
		padding: 0 0.25rem;

		&.active-leaderboard {
			color: var(--text-default);
		}

		&:hover {
			background-color: var(--grey-200);
		}
		.p-text {
			margin-right: 0.188rem;
		}

		svg {
			flex: none;
			width: 1.25rem;
			height: 1.25rem;
		}

		@include breakpoint(mobile) {
			svg {
				height: 1.5rem;
				width: 1.5rem;
			}

			& {
				min-width: 4.75rem;
				height: 2.5rem;
				padding: 0 0.375rem;
			}
		}
	}
}

#user-rating--info .p-overlay-inner {
	padding: 0.75rem;
	.rating-info {
		&--header {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			& svg {
				flex-shrink: 0;
			}

			.rating-info--content {
				padding-inline-start: 0.375rem;
			}
		}
	}
}

#user-survay--info.black .p-overlay-inner {
	background: #0a090b;
	color: var(--white);
	padding: 0.25rem 0.625rem;
}

#user-survay--info.black.p-overlay-panel::before {
	border-bottom-color: #0a090b;
}
</style>
