<script setup lang="ts">
import TransactionsView from '@/views/history/views/transactions/TransactionsView.vue';
import RewardsView from '@/views/history/views/rewards/RewardsView.vue';
import { REWARDS, TRANSACTIONS, HISTORY_PAGE_TITLE } from '@/locales/constants';
import { useHistoryView } from '@/views/history/composables';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import {
	PrimeTabView,
	PrimeTabPanel,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { activeTabId } = useHistoryView();
</script>

<template>
	<LayoutSubpage back-button-visible :title="$t(HISTORY_PAGE_TITLE)">
		<main class="history">
			<PrimeTabView v-model="activeTabId">
				<PrimeTabPanel id="rewards" :header="$t(REWARDS)">
					<RewardsView />
				</PrimeTabPanel>
				<PrimeTabPanel id="transactions" :header="$t(TRANSACTIONS)">
					<TransactionsView />
				</PrimeTabPanel>
			</PrimeTabView>
		</main>
	</LayoutSubpage>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.history {
	width: 100%;
}

.history :deep(.p-tabs) {
	border-bottom: none;
	padding-bottom: 1px;
	overflow-x: visible;
}
:deep(.p-tabs):after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 1px;
	width: calc(100% + 2rem);
	background: #d3def8;

	@include breakpoint(mobile) {
		width: 200%;
	}
}
</style>
