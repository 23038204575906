import { useModalStorage } from '@/utils/composables/useModalStorage';
import { onBeforeMount, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';
import {
	LEADERBOARD_MODAL,
	NEW_PASSWORD_SENT,
	SET_USER_PASSWORD_MODAL,
} from '@/constants/modals';
import { ColorModes, LeaderboardTypes } from '@/enums';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { useMessagesStore } from '@/stores/messages';
import { useAppStore } from '@/stores/app';
import { appColorMode } from '@/utils/local-storage';
import {
	enable as enableDarkMode,
	disable as disableDarkMode,
	auto as followSystemColorScheme,
	setFetchMethod,
} from 'darkreader';

export const useAppInit = () => {
	const modalStorage = useModalStorage();
	const userStore = useUserStore();
	const authStore = useAuthStore();
	const messageStore = useMessagesStore();
	const {
		leaderboard,
		isPasswordSet,
		userData,
		isInitialDataLoading,
		isDarkModeEnabled,
	} = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const { authToken } = useAppCookies();
	const appStore = useAppStore();
	const { getTrackingMaid } = storeToRefs(appStore);

	// Set user token if it is in cookies
	onBeforeMount(() => {
		if (!userAuthToken.value && authToken) {
			authStore.setToken(authToken);
		}
	});

	// Close new password sent modal if it is opened
	onBeforeMount(async () => {
		if (modalStorage.activeModal.value.name === NEW_PASSWORD_SENT) {
			await modalStorage.shiftModal();
		}

		await appStore.fetchAppMeta();
	});

	const init = async () => {
		try {
			userStore.setInitialDataLoading(true);
			await Promise.all([
				userStore.fetchUserData(),
				userStore.fetchUserMeta(),
				userStore.fetchFeatures(),
			]);
		} finally {
			userStore.setInitialDataLoading(false);
		}

		await Promise.allSettled([
			userStore.fetchTiersList(),
			messageStore.fetchUserNotifications(),
			messageStore.fetchUserAnnouncements(),
		]);

		if (getTrackingMaid.value) {
			await appStore.storeMaid();
		}
	};

	// Fetch user data based on token
	watch(
		userAuthToken,
		async (token) => {
			if (token) {
				await init();

				//If user does not have password - show modal to set it
				if (userData.value && !isPasswordSet.value) {
					await modalStorage.pushModal({
						name: SET_USER_PASSWORD_MODAL,
						options: { useDefaultClose: false },
					});
				}
			}
		},
		{ immediate: true }
	);

	// Fetch user data based on user id from fetched previously user data
	watch(isInitialDataLoading, async (newValue, oldValue) => {
		const dataFetchCompleted = !newValue && oldValue;
		if (dataFetchCompleted && leaderboard.value.has_enough_completes) {
			await modalStorage.pushModal({
				name: LEADERBOARD_MODAL,
				options: { type: LeaderboardTypes.UNLOCKED },
			});
		}
	});

	onMounted(() => {
		console.log('ENVS:::');
		console.log(import.meta.env);
	});

	const darkReaderTheme = {
		brightness: 100,
		contrast: 100,
		sepia: 0,
	};

	watch(
		appColorMode,
		(value) => {
			setFetchMethod(window.fetch);
			switch (value) {
				case ColorModes.LIGHT:
					disableDarkMode();
					break;
				case ColorModes.DARK:
					enableDarkMode(darkReaderTheme);
					break;
				case ColorModes.SYSTEM:
					followSystemColorScheme(darkReaderTheme);
					break;
			}
		},
		{ immediate: true }
	);

	watch(
		isDarkModeEnabled,
		(newValue) => {
			// we need such check because initial value of isDarkModeEnabled is undefined
			if (newValue === false && appColorMode.value !== ColorModes.LIGHT) {
				appColorMode.value = ColorModes.LIGHT;
			}
		},
		{ immediate: true }
	);
};
